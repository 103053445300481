import { createElement, FC } from 'react';
import { useHttpQuery } from 'packages/http-client/react';
import { replaceHyphens } from 'apps/acp/packages/brand-name';
import { addSuperscripts } from 'apps/acp/packages/issuer-statement';
import { createGetBrandingProductUpgradeRequest } from 'apps/acp/packages/webapi';

export const UpgradeIssuerStatement: FC = () => {
  const {
    brand: { issuer_statement_html }
  } = useHttpQuery(createGetBrandingProductUpgradeRequest());
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: addSuperscripts(replaceHyphens(issuer_statement_html))
      }}
    ></span>
  );
};
