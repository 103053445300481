import { createElement, FC } from 'react';
import { useHttpQuery } from 'packages/http-client/react';
import { addSuperscripts } from 'apps/acp/packages/issuer-statement';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';

export const IssuerStatement: FC = () => {
  const {
    brand: { issuer_statement_html }
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: addSuperscripts(issuer_statement_html)
      }}
    ></span>
  );
};

export * from './upgrade';
